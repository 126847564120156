import React from "react"
import tw, { styled, css } from "twin.macro"
import SectionHeader from "./SectionHeader"
import { motion } from "framer-motion"

const Modal = ({ isShown, handleModal, variant }) => {
  const modalVariants = {
    visible: {
      display: "block",
      opacity: 1,
    },
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
    },
  }

  return (
    <Container
      rel="nofollow"
      transition={{ duration: 0.4, ease: "easeOut" }}
      variants={modalVariants}
      initial="hidden"
      animate={isShown ? "visible" : "hidden"}
      onClick={() => handleModal()}
    >
      {variant === "tos" ? (
        <ModalContent>
          <SectionHeader tag="Terms of Service" size="sm" text={`Regulamin`} />
          <Subheader>I. Zasady ogólne.</Subheader>
          <Text>
            Każdy Klient Nemezink (pełna nazwa: Michał Popiół Nemezink, 1
            Brygady 16/33, 33-300 Nowy Sącz, NIP: 734-358-55-06 zwane dalej
            Studiem) jest zobowiązany do zapoznania się z regulaminem
            świadczonych usług, który stanowi nieodłączny element umowy
            zawieranej między Tobą a Studiem. Jest przy tym również zobowiązany
            do zapoznania się z naszą Polityką Prywatności, która przedstawia
            podstawy prawne przetwarzania danych osobowych Klienta i jest
            nieodłącznym elementem tego regulaminu.
          </Text>
          <Subheader>
            II. Potwierdzenie, zmiana oraz odwołanie terminu przez Klienta.
          </Subheader>
          <Text>
            <p>
              Wstępna rezerwacja wymaga wpłaty zadatku w wysokości minimum 150
              zł. Opłata musi zostać uiszczona w terminie do 2 dni roboczych od
              dokonania rezerwacji. Można jej dokonać osobiście w Nemezink za
              pomocą gotówki/karty płatniczej lub przelewem bankowym. Rezerwacja
              będzie respektowana jedynie po zaksięgowaniu/otrzymaniu zadatku. W
              przypadku chęci zmiany terminu lub jego odwołania Klient powinien
              poinformować Studio o tym co najmniej 3 dni robocze przed
              zarezerwowanym terminem.{" "}
            </p>
            <p>
              W przypadku braku poinformowania później niż na 3 dni robocze
              przed terminem lub niestawienie się w umówionym terminie jest
              równoznaczne z utratą wpłaconego zadatku. Aby zarezerwować nowy
              termin, Klient jest zobowiązany do wpłacenia nowego zadatku w
              wysokości minimum 150 zł w celu potwierdzenia. Każdorazowe
              dokonanie rezerwacji wiąże się z akceptacją naszego regulaminu.
            </p>
          </Text>
          <Subheader>III Ceny zabiegów.</Subheader>
          <Text>
            Każda cena ustalana jest indywidualnie, wyznaczając 300 zł jako
            kwotę minimalną. Cena usługi zależna jest od wielkości, miejsca,
            stopnia skomplikowania oraz wykonującego tatuażysty. Wcześniej
            wpłacony zadatek wliczany jest na poczet ostatecznej ceny tatuażu.
          </Text>
          <Subheader>IV Odwołanie i zmienianie terminu przez Studio.</Subheader>
          <Text>
            <p>
              Zastrzegamy sobie prawo do zmiany terminu do 1 dnia roboczego
              przed zarezerwowanym terminem. W przypadku braku akceptacji nowego
              terminu przez Klienta zadatek zostanie zwrócony. Zastrzegamy sobie
              również prawo do odmówienia wykonania zabiegu bez podania
              przyczyny. W takiej również zadatek zostanie w pełni zwrócony
              Klientowi.
            </p>
            <p>
              Tatuator może odmówić zabiegu lub przełożyć termin, jeśli stan
              skóry, zdrowia lub kondycja Klienta nie pozwala na poprawne
              wykonanie zabiegu.{" "}
            </p>
          </Text>
          <Subheader>V Konsultacje.</Subheader>
          <Text>
            <p>
              Jako Nemezink zalecamy konsultacje przed umówionym terminem w celu
              ustalenia wzoru, rozmiaru, sposobu wykonania, miejsca, preferencji
              oraz innych aspektów, które mogą mieć wpływ na efekt zabiegu.
              Konsultacja nie jest obowiązkowa, natomiast jej koszt wynosi 50zł
              (do 30min), lub 100zł (30min-1h).
            </p>
            <p>
              W przypadku jakichkolwiek wątpliwości co do pielęgnacji tatuażu
              lub procesu gojenia Klient ma prawo skontaktować się ze Studiem.
              To z kolei zobowiązuje się do udzielenia wyczerpujących odpowiedzi
              na wszystkie pytania.
            </p>
          </Text>
          <Subheader>VI Efekt końcowy.</Subheader>
          <Text>
            <p>
              Z racji, że tatuaż wykonywany jest na skórze, to należy wziąć pod
              uwagę, że jako materiał nośny jest ona materiałem wyjątkowo
              trudnym. Ze względu na cechy indywidualne Klienta, takie jak:
              wiek, typ, choroby, alergie, wiotkość, poziom zadbania, miejsce
              zabiegu – efekt zabiegu może różnić się od przedstawianego wzoru.{" "}
            </p>
            <p>
              Na efekt zabiegu ma również wpływ odpowiednia pielęgnacja. O
              wszystkich zastrzeżeniach, praktykach i metodach każdorazowo
              informuje tatuażysta. Za wszelkie uszkodzenia i zniekształcenia
              powstałe wskutek niedbałości Klienta, nie odpowiada ani Studio,
              ani wykonujący tatuażysta.
            </p>
            <p>
              Studio nie odpowiada za reakcje alergiczne Klienta na użyte
              barwniki.
            </p>
          </Text>
          <Subheader>VII Sterylność.</Subheader>
          <Text>
            Studio zobowiązuje się do używania nowych produktów jednorazowych,
            które po użyciu podlegają kompletnej utylizacji zgodnie z
            odpowiednimi normami o odpadach niebezpiecznych. W przypadku użycia
            produktów lub narzędzi wielokrotnego użytku Studio zobowiązuje się
            do odpowiedniej dezynfekcji i sterylizacji owych przedmiotów
            odpowiednio wcześniej przed wykonaniem zabiegu. Produkty
            przeznaczone do wykonania zabiegu, w tym w szczególności barwniki,
            spełniają normy i atesty mające do nich zastosowanie.
          </Text>
          <Subheader>VIII Alkohol, narkotyki i inne używki.</Subheader>
          <Text>
            Klient nie może być pod wpływem alkoholu, narkotyków lub innych
            substancji psychoaktywnych. W przypadku niedotrzymania tego punktu
            tatuator ma prawo odmówić wykonania zabiegu, a Studio nie ma
            obowiązku zwrócenia wpłaconego zadatku.
          </Text>
          <Subheader>IX Zachowanie Klienta.</Subheader>
          <Text>
            <p>
              Klient zobowiązany jest do współpracowania z tatuatorem oraz
              przestrzegania zasad sanitarnych, o których zostanie poinformowany
              indywidualnie przed zabiegiem. Jakiekolwiek zastrzeżenia do wzoru,
              pracy lub techniki tatuatora Klient powinien zgłosić na bieżąco w
              trakcie zabiegu. Ze względu na komfort innych Klientów oraz
              tatuatorów zalecamy ograniczenie głośnych rozmów, muzyki oraz
              wszelkich zachowania powszechnie uznawanych za niekulturalne. W
              przypadku nieprzestrzegania wyżej wymienionych zasad tatuator ma
              prawo przerwać wykonywanie zabiegu i odmówić dalszej pracy.
            </p>
            <p>
              Na terenie Studia kategorycznie zabrania się palenia wyrobów
              tytoniowych, papierosów elektronicznych, spożywania alkoholu,
              zażywania wszelkich substancji psychoaktywnych oraz wprowadzania
              zwierząt. Ze względu na sterylność nie wolno również spożywać
              posiłków na sali, gdzie wykonywane są zabiegi. Studio nie zaleca
              również przyprowadzania ze sobą dzieci.{" "}
            </p>
            <p>
              Jeśli samopoczucie Klienta w trakcie zabiegu się pogorszy lub
              nastąpi niewłaściwa reakcja skóry, tatuator przerwie zabieg.{" "}
            </p>
          </Text>
          <Subheader>X Minimalny wiek Klienta.</Subheader>
          <Text>
            Klient musi mieć ukończone 18 lat. W wyjątkowych sytuacja dopuszcza
            się zabieg u osób między 17 a 18 rokiem życia. W tej sytuacji Studio
            przesyła na podany przez Klienta adres mailowy szablon pisemnej
            zgody na wykonanie tatuażu, który musi zostać dostarczony do Studio
            wraz z podpisem opiekuna prawnego w dniu wizyty.
          </Text>
          <Subheader>XI Prawa autorskie i promocja.</Subheader>
          <Text>
            Studio zastrzega sobie prawo do wykonywania zdjęć tak projektom, jak
            i wykonanym tatuażom w celu publikacji, promocji oraz prezentacji
            ich w celach marketingowych. Klient wyraża zgodę na to poprzez
            rezerwacje terminu oraz wpłacenie zaliczki. Wszystkie projekty
            wykonane przez tatuatorów Nemezink objęte są prawami autorskimi.
            Wykonanie tatuażu na podstawie ich wzoru stanowi naruszenie praw
            autorskich i naraża Klienta na konsekwencje prawne.
          </Text>
          <Subheader>XII Reklamacje i pełnomocnictwa</Subheader>
          <Text>
            Wszelkie reklamacje należy zgłaszać osobiście managerowi Studio pod
            adresem SANDOMIERSKA 3/LU2, KRAKÓW lub drogą mailową pod adresem:
            nemezink@gmail.com. Jedynie manager jest upoważniony do wypowiadania
            się w imieniu Nemezink oraz do uwzględniania reklamacji. Studio nie
            odpowiada za ustalenia powstałe między tatuatorem a Klientem, które
            mogą wykraczać poza przedstawiony regulamin.
          </Text>
        </ModalContent>
      ) : (
        <ModalContent>
          <SectionHeader
            tag="Policy Privacy"
            text={`Polityka Prywatności Nemezink`}
            size="sm"
          />
          <Subheader>Informacje wstępne i używane pojęcia</Subheader>
          <Text>
            <p>
              Aby Polityka Prywatności była czytelna, w tych miejscach, gdzie
              tylko było to możliwe odeszliśmy od stosowania formalnego,
              prawniczego słownictwa, dlatego też pisząc ,,Ty”, ,,Ci” ,,Tobie”
              mamy na myśli użytkownika naszej strony internetowej. Poniżej
              znajduje się lista definicja, która może okazać się pomocna w
              lepszym zrozumieniu polityki prywatności.
            </p>
            <p>
              (a) Operator to firma Michał Popiół Nemezink, z siedzibą pod
              adresem 1 Brygady 16/33, 33-300 Nowy Sącz, posługujący się numerem
              NIP 7343585506, REGON 386825885
              <br />
              (b) RODO to Rozporządzenie Parlamentu Europejskiego i Rady (UE)
              2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
              fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
              swobodnego przepływu takich danych oraz uchylenia dyrektywy
              95/46/WE (ogólne rozporządzenie o ochronie danych);
              <br />
              (c) Serwis to strona internetowa www.nemezink.pl oraz świadczone
              za jej pośrednictwem usługi;
              <br />
              (d) Dane osobowe lub ​dane to wszelkie uzyskane od Ciebie w ramach
              Serwisu informacje, które mogą pozwolić nam Cię zidentyfikować.
              Szerszą definicję znajdziesz w art. 4 pkt 1 RODO;
              <br />
              (e) Przetwarzanie danych to operacja lub zestaw operacji jakie
              wykonujemy na danych osobowych – są to m.in. takie czynności jak:
              zbieranie, przechowywanie, porządkowanie, wykorzystywanie tych
              danych;
            </p>
            <p>
              W razie jakichkolwiek wątpliwości związanych z polityką
              prywatności, prosimy o wysłanie wiadomości przez formularz
              kontaktowy zawarty na stronie internetowej www.nemezink.pl lub na
              maila nemezink@gmail.com
            </p>
          </Text>
          <Subheader>Dane osobowe</Subheader>
          <Text>
            Administratorem Twoich danych jest firma Michał Popiół Nemezink, z
            siedzibą pod adresem 1 Brygady 16/33, 33-300 Nowy Sącz, posługujący
            się numerem NIP 7343585506, REGON 386825885. Administrator dokłada
            szczególnej staranności w celu ochrony interesów osób, których
            przetwarzane przez niego dane osobowe dotyczą, a w szczególności
            jest odpowiedzialny i zapewnia, że zbierane przez niego dane są: (1)
            przetwarzane zgodnie z prawem; (2) zbierane dla oznaczonych,
            zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu
            niezgodnemu z tymi celami; (3) merytorycznie poprawne i adekwatne w
            stosunku do celów, w jakich są przetwarzane; (4) przechowywane w
            postaci umożliwiającej identyfikację osób, których dotyczą, nie
            dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania oraz
            (5) przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo
            danych osobowych, w tym ochronę przed niedozwolonym lub niezgodnym z
            prawem przetwarzaniem oraz przypadkową utratą, zniszczeniem lub
            uszkodzeniem, za pomocą odpowiednich środków technicznych lub
            organizacyjnych.
          </Text>
          <Subheader>Cele oraz podstawa prawna</Subheader>
          <Text>
            <p>
              Obsługa zgłoszeń kierowanych z wykorzystaniem formularza
              kontaktowego lub innych wniosków. Podstawa prawna: art. 6 ust. 1
              lit. a (wyrażenie zgody na przetwarzanie) F RODO (prawnie
              uzasadniony interes Administratora).
              <br />
              (f) Ustalenie i dochodzenie roszczeń lub obrona przed nimi.
              Podstawa prawna: art. 6 ust. 1 lit. F RODO (prawnie uzasadniony
              interes Administratora).
              <br /> (g) Realizacja praw podmiotów danych. Podstawa prawna: art.
              6 ust. 1 lit. F RODO (prawnie uzasadniony interes Administratora).
            </p>
            <p>
              Twoje dane osobowe są przez nas przetwarzane zgodnie z przepisami
              prawa, w tym w szczególności zgodnie z ustawą z dnia 10 maja 2018
              r. o ochronie danych osobowych oraz Rozporządzenia Parlamentu
              Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
              sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
              osobowych i w sprawie swobodnego przepływu takich danych oraz
              uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
              danych).
            </p>
            <p>
              Przekazywane nam dane osobowe przetwarzamy z największą
              starannością, zgodnie z obowiązującymi przepisami prawa
              przestrzegając przy tym Twoje prawo do prywatności. Gwarantujemy
              CI, że korzystając z naszego Serwisu będziesz mógł w każdej chwili
              złożyć wniosek o udostępnienie swoich danych osobowych. Twoje dane
              osobowe są chronione przed dostępem do nich przez osoby
              nieupoważnione.
            </p>
            <p>
              Pozostałe cele oraz podstawy prawne wskazane są odrębnie w
              stosunku do każdego celu przetwarzania danych osobowych.
            </p>
            <p>
              Twoje dane dla naszych celów marketingowych będą przetwarzane
              wyłącznie za Twoją zgodą. Może ona zostać przez Ciebie wyrażona
              poprzez wypełnienie formularza na otrzymanie informacji handlowych
              drogą elektroniczną (newsletter) lub poprzez zaznaczenie
              odpowiedniego pola przy formularzu kontaktowym. Zgoda ta może być
              w każdej chwili cofnięta bez uszczerbku dla świadczonych przez nas
              dla Ciebie między innymi usług tatuażu. Ponadto, cofnięcie tej
              zgody nie naruszy prawidłowości przetwarzania danych. Wyrażenie
              zgody na przetwarzanie danych w celach marketingowych nie ma
              wpływu na Twoje korzystanie z naszego Serwisu.
            </p>
          </Text>
          <Subheader>Uprawnienia</Subheader>
          <Text>
            <p>
              RODO przyznaje Ci następujące potencjalne uprawnienia związane z
              przetwarzaniem Twoich danych osobowych:
            </p>
            <p>
              Prawo dostępu do swoich danych, w tym do otrzymania ich kopii,
              <br />
              Prawo do sprostowania (poprawiania) swoich danych,
              <br />
              Prawo do usunięcia danych (jeżeli Twoim zdaniem nie ma podstaw,
              abyśmy przetwarzali Twoje dane, możesz zażądać abyśmy je usunęli)
              <br />
              Prawo do ograniczenia przetwarzania danych
              <br />
              Prawo do wniesienia sprzeciwu wobec przetwarzanych danych
              <br />
              Prawo do przenoszenia danych osobowych
              <br />
              Prawo do wniesienia skargi do organu nadzorczego - Prezesa Urzędu
              Ochrony Danych
              <br />
              Osobowych lub innego właściwego organu nadzorczego ze względu na
              Twoje miejsce zamieszkania, miejsce prowadzenia działalności
              gospodarczej lub miejsca jej domniemanego naruszenia.
            </p>
            <p>
              Zasady związane z realizacją wskazanych powyżej uprawnień zostały
              opisane szczegółowo w art. 16-21 RODO. Zachęcamy do zapoznania się
              z tymi przepisami. Jednocześnie informujemy, że wskazane powyżej
              uprawnienia nie są bezwzględne i nie przysługują Ci w stosunku do
              wszystkich czynności przetwarzania Twoich danych osobowych.
              Przykładem takiego działania może być obowiązek zachowania przez
              Administratora danych o świadczonych przez niego usługach przez
              okres co najmniej 5 lat w przypadku, gdy Twoje dane są związane z
              jego rozliczeniami podatkowymi bądź rachunkowymi.
            </p>
            <p>
              Jeśli jednak uznasz, że przy przetwarzaniu Twoich danych osobowych
              dopuściliśmy się naruszenia przepisów o ochronie danych osobowych,
              masz możliwość wniesienia skargi do organu nadzorczego (Prezesa
              Urzędu Ochrony Danych Osobowych)
            </p>
            <p>
              Aby skorzystać ze swoich praw skontaktuj się z nami za
              pośrednictwem formularza kontaktowego (lepiej byłoby podać adres
              e-mail wszędzie w Polityce Prywatności).
            </p>
            <p>
              Zgłoszone przez Ciebie żądanie zostanie zrealizowane niezwłocznie,
              nie później niż w ciau 72 godzin od jego zgłoszenia. W celu
              weryfikacji Twojej tożsamości możesz zostać poproszony o
              przeslanie skanu dowodu osobistego względnie paszportu lub
              rachunku, na którym widnieją dane Serwisu. Ani te dokumenty, ani
              informacje, które przedstawiają, nie są jednak wprowadzane do
              żadnego zbioru danych osobowych, a usuwa się je natychmiast po
              zakończeniu procesu weryfikacji. Działanie takie jest niezbędne
              dla do ochrony żywotnych interesów osoby, której dane dotyczą
              (art. 6 ust. 1 lit. d RODO).
            </p>
          </Text>
          <Subheader>Bezpieczeństwo</Subheader>
          <Text>
            Zapewniamy poufność wszelkich przekazanych nam danych osobowych.
            Zapewniamy podjęcie odpowiednich środków bezpieczeństwa i ochrony
            danych osobowych wymaganych przez przepisy o ochronie danych
            osobowych. Dane osobowe są gromadzone z należytą starannością i
            odpowiednio chronione przed dostępem do nich przez osoby do tego
            nieupoważnione
          </Text>
          <Subheader>Odbiorcy danych</Subheader>
          <Text>
            <p>
              Twoje dane osobowe mogą być przetwarzane przez podmioty, z których
              usług korzystamy, a których usługi wiążą się lub mogą wiązać się z
              przetwarzaniem danych osobowych. W szczególności chodzi o
              następujące podmioty:
              <br />
              (a) Podmiot odpowiedzialny za hosting strony www
              <br />
              (b) Podmiot świadczący usługi w zakresie obsługi technicznej
              strony internetowej
              <br />
              (c) Kancelaria prawna
              <br />
              (d) Pozostali podwykonawcy
              <br />
            </p>
            <p>
              Są to zazwyczaj podmioty, które wspierają nas w zakresie
              dostarczania narzędzi pomocnych dla zapewniania komunikacji z Tobą
              między innymi w zakresie usługi newsletter. Pomagają one nam
              również w zrozumieniu Twojego ruchu w sieci na naszym Serwisie czy
              też Twojego sposobu interakcji z naszym Serwisem.
            </p>
            <p>
              Przekazywanie Twoich danych może odbywać się również w
              następujących celach:
              <br />
              a) analitycznych (tj. doboru usług do potrzeb Klientów;
              optymalizacji produktów na podstawie Twojego zainteresowania,
              logów technicznych; optymalizacji procesów obsługi na podstawie
              przebiegu obsługi sprzedażowej i posprzedażowej, w tym
              reklamacyjnej; badania satysfakcji Klientów i określania jakości
              naszej obsługi).
              <br />
              b) archiwalnych dla zabezpieczenia informacji,
              <br />
              c) ewentualnego ustalenia, dochodzenia lub obrony przed
              roszczeniami,
              <br />
              d) oferowania produktów bezpośrednio (marketing bezpośredni), w
              tym dobierania ich pod kątem potrzeb (patrz Polityka Cookies)
              <br />
              e) w celu oferowania bezpośrednio (marketing bezpośredni)
              produktów i usług firm z nami współpracujących (np. w formie
              kuponów rabatowych) w zakresie określonym przeprowadzoną i
              udokumentowaną analizą tak uzasadnionego interesu.
              <br />
            </p>
            <p>
              Ponadto, w razie zaistnienia takiej konieczności, Twoje dane
              osobowe mogą być udostępniane podmiotom, organom lub instytucjom
              uprawnionym do uzyskania dostępu do danych na podstawie przepisów
              prawa, takim jak służby policyjne, służby bezpieczeństwa, sądy,
              prokuratury. Zrobimy to wówczas, gdy będzie ciążył na nas prawny
              obowiązek wynikający z art. 6 ust. 1 lit. c RODO. Nie zakładamy,
              że będziemy przekazywać dane do Państw trzecich, czyli takich
              które znajdują się poza EOG (Europejski Obszar Gospodarczy).
            </p>
          </Text>
          <Subheader>Przechowywane dane</Subheader>
          <Text>
            W ramach naszej strony internetowej, a w szczególności naszego
            formularzu kontaktowego przetwarzamy takie dane jak: imię, nazwisko,
            adres email oraz w przypadku nadesłania nam pliku elektronicznego,
            również i dane zawarte w tym pliku niezbędne do realizacji
            zamówionej przez Ciebie za pomocą formularza kontaktowego usługi.
          </Text>
          <Subheader>Okres przechowywania danych</Subheader>
          <Text>
            Twoje dane osobowe przechowywane są w formie umożliwiającej
            identyfikację osób, których dane dotyczą, przez okres nie dłuższy
            niż jest to konieczne do celów, dla których przetwarzane są dane
            osobowe (prawidłowe wykonanie umowy, realizacja obowiązków prawnych
            z zakresu prawa podatkowego, marketing bezpośredni). Po tym czasie
            dane są przechowywane wyłącznie w celu zabezpieczenia roszczeń
            prawnych, względnie usuwane.
          </Text>
          <Subheader>Pliki cookies I inne technologie śledzące</Subheader>
          <Text>
            <p>
              Na naszej stronie korzystamy z plików cookies (tzw. Ciasteczek) i
              innych podobnych technologii w Twoim urządzeniu. Ciasteczka to
              informacje tekstowe przechowywane na Twoim urządzeniu końcowym,
              które mogą być odczytywane przez nasz system lub system podmiotów
              trzecich (na przykład podmioty, którym przekazujemy Twoje dane -
              podmioty świadczące usługi techniczne).
            </p>
            <p>
              Cookies zazwyczaj zawierają nazwę domeny serwisu internetowego, z
              którego pochodzą, czas przechowywania ich na urządzeniu końcowym
              oraz unikalny numer. Pliki cookies nie służą do identyfikacji
              Użytkownika i na ich podstawie nie jest ustalana Twoja tożsamość.
              Strona internetowa może umieścić pliki cookies w przeglądarce,
              jeśli tylko przeglądarka to umożliwia. Przeglądarka zezwala
              stronie internetowej na dostęp jedynie do plików cookies
              umieszczonych przez tę stronę, a nie do plików umieszczonych przez
              inne strony internetowe.
            </p>
            <p>
              Ponadto, pliki cookies zamieszczane w Twoim urządzeniu
              (przeglądarce internetowej) mogą być przekazywane i wykorzystywane
              przez współpracujących z Administratorem reklamodawców czy też
              jego partnerów.
            </p>
            <p>
              Z plików cookies i innych podanych technologii korzystamy w celach
              świadczenia usług, dostosowania zawartości do Twoich preferencji
              oraz do optymalizacji korzystania ze strony internetowej. Dzięki
              nim tworzymy statystyki, które pozwalają zrozumieć w jaki sposób
              Użytkownicy korzystają z naszych serwisów, co umożliwi ulepszenie
              ich struktury i zawartości. Dodatkowo, pomagają one w rozpoznaniu
              Twojego urządzenia i dzięki temu odpowiednio dostosować
              pojawiające się na stronie internetowej treści do Twoich
              preferencji. Ostatecznie, w przypadku utworzenia konta na naszej
              stronie internetowej, pliki cookies pozwalają również na
              zachowanie aktywnej sesji zalogowania, przez co na kolejnych
              podstronach nie musisz za każdym razem wpisywać swojego loginu czy
              też hasła.
            </p>
          </Text>
          <Subheader>Rodzaje cookies</Subheader>
          <Text>
            Stosujemy dwa zasadnicze rodzaje plików cookies: sesyjne- pliki
            tymczasowe, przechowywane w urządzeniu końcowym Użytkownika do czasu
            opuszczenia strony internetowej i aplikacji lub wyłączenia
            oprogramowania (przeglądarki internetowej); oraz pliki stałe -
            przechowywane w urządzeniu końcowym przez czas określony w
            parametrach plików cookies lub do czasu ich usunięcia przez
            Użytkownika.
            <p>
              Na naszej stronie internetowej stosowane są następujące rodzaje
              plików cookies:
              <br /> 1. „niezbędne” pliki cookies, umożliwiające korzystanie z
              usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki
              cookies wykorzystywane do usług wymagających uwierzytelniania w
              ramach Serwisu; <br />
              2. pliki cookies służące do zapewnienia bezpieczeństwa, np.
              wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w
              ramach Serwisu; <br />
              3. „wydajnościowe” pliki cookies, umożliwiające zbieranie
              informacji o sposobie korzystania ze stron internetowych Serwisu;{" "}
              <br />
              4. „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie”
              wybranych przez Użytkownika ustawień i personalizację interfejsu
              Użytkownika, np. w zakresie wybranego języka lub regionu, z
              którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony
              internetowej itp.;
              <br />
              5. „reklamowe” pliki cookies, umożliwiające dostarczanie
              Użytkownikom treści reklamowych bardziej dostosowanych do ich
              zainteresowań.
            </p>
          </Text>
          <Subheader>Zgoda na cookies</Subheader>
          <Text>
            <p>
              Podczas pierwszej wizyty na naszej stornie wyświetlana jest Ci
              informacja na temat stosowania plików cookies. Korzystając z
              naszej strony z włączoną w przeglądarce obsługą plików cookies
              wyrażasz zgodę na wykorzystanie plików cookies.{" "}
            </p>
            <p>
              Te ustawienia możesz samodzielnie zmienić z poziomu swojej
              przeglądarki lub usunąć całkowicie pliki cookies. W niektórych
              przeglądarkach istnieje możliwość zaakceptowania obsługi cookies,
              co umożliwia Ci pełne korzystanie z opcji oferowanych przez
              witryny internetowe; zarządzanie plikami cookies na poziomie
              pojedynczych wybranych przez Ciebie witryn i tym podobne.
              Kompleksowe informacje na ten temat dostępne są w ustawieniach
              przeglądarki internetowej. Informujemy, że wyłączenie bądź
              ograniczenie stosowania plików cookies i innych podobnych może
              wpłynąć na niektóre funkcjonalności dostępne w naszym serwisie.
            </p>
          </Text>
          <Subheader>Zgoda na cookies</Subheader>
          <Text>
            <p>
              Podczas pierwszej wizyty na naszej stornie wyświetlana jest Ci
              informacja na temat stosowania plików cookies. Korzystając z
              naszej strony z włączoną w przeglądarce obsługą plików cookies
              wyrażasz zgodę na wykorzystanie plików cookies.
            </p>
            <p>
              W każdej chwili możesz dokonać zmiany ustawień dotyczących plików
              cookies. Możesz tego dokonać między innymi poprzez blokowanie
              automatycznej obsługi plików cookies w ustawieniach przeglądarki
              internetowej. Szczegółowe informacje o tego rodzaju udogodnieniach
              powinny znajdować się w ustawieniach Twojej przeglądarki
              internetowej. Niemniej jednak, ograniczenie korzystania z plików
              cookies może spowodować ograniczenie niektórych funkcjonalności
              występujących i dostarczanych Ci przez naszą stronę internetową.
            </p>
            <p>
              Jak napisaliśmy powyżej, te ustawienia możesz samodzielnie zmienić
              z poziomu swojej przeglądarki lub usunąć całkowicie pliki cookies.
              W niektórych przeglądarkach istnieje możliwość zaakceptowania
              obsługi cookies, co umożliwia Ci pełne korzystanie z opcji
              oferowanych przez witryny internetowe; zarządzanie plikami cookies
              na poziomie pojedynczych wybranych przez Ciebie witryn i tym
              podobne. Kompleksowe informacje na ten temat dostępne są w
              ustawieniach przeglądarki internetowej. Informujemy, że wyłączenie
              bądź ograniczenie stosowania plików cookies i innych podobnych
              może wpłynąć na niektóre funkcjonalności dostępne w naszym
              serwisie.
            </p>
          </Text>
          <Subheader>Google Analytics</Subheader>
          <Text>
            W ramach naszej strony internetowej korzystamy z narzędzia Google
            Analytics zapewnianego przez Google LLC, 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA. Działania w tym zakresie realizujemy,
            opierając się na naszym prawnie uzasadnionym interesie, polegającym
            na tworzeniu statystyk i ich analizie w celu optymalizacji naszych
            stron internetowych. Google Analytics w sposób automatyczny gromadzi
            informacje o Twoim korzystaniu z naszej strony. Zgromadzone w ten
            sposób informacje są najczęściej przekazywane do serwera Google w
            Stanach Zjednoczonych i tam przechowywane. Z uwagi na to, że Google
            LLC posiada siedzibą w USA i wykorzystuje infrastrukturę techniczną
            znajdującą się w USA, przystąpił do programu EU- US-Privacy Shield w
            celu zapewnienia odpowiedniego poziomu ochrony danych osobowych
            wymaganego przez przepisu europejskie. W ramach umowy pomiędzy USA a
            Komisją Europejską ta ostatnia stwierdziła odpowiedni poziom ochrony
            danych w przypadku przedsiębiorstw posiadających certyfikat Privacy
            Shield. W ramach ustawień plików cookies dostępnych z poziomu naszej
            strony możesz zadecydować, czy wyrażasz zgodę na korzystanie przez
            nas w Twoim przypadku z Google Analytics czy nie.
          </Text>
          <Subheader>Mailerlite.com</Subheader>
          <Text>
            <p>
              W celu dostarczenia na Twoją rzecz usługi newsletterowej
              korzystamy z usługi osoby trzeciej działającej pod firmą
              MailerLite Limited, Irlandzkiego przedsiębiorcy z siedzibą w
              Ground Floor, 71 Lower Baggot Street, Dublin 2, D02 P593, Ireland,
              któremu przekazujemy Twoje dane osobowe dla celów przygotowania
              spersonalizowanego maila czy też przeprowadzenia kampanii
              newsletterowej.
            </p>
            <p>
              Polityka prywatności naszego partnera jest dostępna pod adresem:
            </p>
            <p>https://www.mailerlite.com/legal/privacy-policy</p>
          </Text>
        </ModalContent>
      )}
    </Container>
  )
}

export default Modal

const Container = styled(motion.div)`
  ${tw`fixed top-0 left-0 w-full h-full overflow-scroll`}
  padding: 4vw 6vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  overflow-x: hidden;
`

const ModalContent = styled.div`
  ${tw`w-full flex flex-col items-start`}
  background-color: #171717;
  padding: 3vw 5vw 3vw 5vw;
  margin-bottom: 4vw;
`

const Subheader = styled.p`
  font-family: "Roboto";
  color: #e7e7e7;
  font-weight: 600;
  font-size: 1.3vw;
  @media (max-width: 1024px) {
    font-size: 4.5vw;
  }
`

const Text = styled.span`
  ${tw`w-full`}
  font-family: "Roboto";
  color: #e7e7e7;
  @media (max-width: 1024px) {
    font-size: 3.2vw;
  }
`
